<template>

	<div id="app">

		<button id="menu-open-close" class="hamburger hamburger--spin" type="button" v-on:click="toggleMenu()" aria-label="Abrir/Fechar Menu">
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>

		<div class="lettering">
			<img src="./images/lettering.svg">
		</div>
		
		<div id="main-menu" class="d-flex flex-column flex-lg-row justify-content-center align-items-center">
			<nav>
				<router-link to="/" exact class="menu-link" @click.native="toggleMenu()"><small>01.</small>Início</router-link>
				<router-link to="/historia" class="menu-link" @click.native="toggleMenu()"><small>02.</small>História</router-link>
				<router-link to="/produtos" class="menu-link" @click.native="toggleMenu()"><small>03.</small>Produtos</router-link>
				<router-link to="/receitas" class="menu-link" @click.native="toggleMenu()"><small>04.</small>Receitas</router-link>
				<router-link to="/ondecomprar" class="menu-link" @click.native="toggleMenu()"><small>05.</small>Onde Comprar</router-link>
				<router-link to="/contactos" class="menu-link" @click.native="toggleMenu()"><small>06.</small>Contactos</router-link>
			</nav>

			<div class="logotipo">
				<img src="./images/logo.svg">
			</div>
		</div>

		<transition name="fadeSlide" mode="out-in" @afterEnter="afterEnter">
			<router-view />
		</transition>

	</div>
</template>

<script>
import globals from '@/config/globals';
import {bus} from '@/main';

export default {
	name: "App",
	data() {
		return {
			dataReady: false,
			pageinfo: null,

			metaTitle: 'Início',
			metaDescription: 'Fritas sem pressas no Alentejo',
			metaImage: globals.BASE_URL + '/images/share-image.jpg'
		};
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			titleTemplate: '%s | Cerro da Águia - Batatas Fritas',
			meta: [
				{ name: 'description', content: this.metaDescription },
				{ property: 'og:site_name', content: 'Cerro da Águia' },
				{ property: 'og:title', content: this.metaTitle + ' | Cerro da Águia - Batatas Fritas' },
				{ property: 'og:description', content: this.metaDescription },
				{ property: 'og:image', content: this.metaImage },
				{ property: 'og:url', content: globals.BASE_URL + this.$route.fullPath },
			] 
		}
	},
  	methods: { 
		toggleMenu() {
			var hamburger = document.getElementById("menu-open-close");
			var menu =  document.getElementById("main-menu");
			hamburger.classList.toggle("is-active");
			menu.classList.toggle("is-active");
		},
		afterEnter() {
			console.log('after enter');
			bus.$emit('transition-done');
		}
	}
};
</script>