<template>

	<div id="home" v-if="dataReady">

		<div id="home-title-container" class="row mb100 d-flex align-items-end align-items-md-center justify-content-center justify-content-md-start">

			<div id="home-title">
				<h1>
    				<img src="@/images/textos/intro-home.svg" width="100%" class="mb10" :alt="pageinfo.titulopag" />
				</h1>
				<h2>{{ pageinfo.subtitulopag }}</h2>
				<router-link to="/historia" tag="button" class="btn btn-outline-light">{{ pageinfo.calltoaction1 }}</router-link>
			</div>

			<img src="@/images/grunge-border-2.png" class="grunge">
		</div>

		<div id="home-produtos" class="container-fluid">

			<div class="container text-center mb50">
				<img src="@/images/textos/batatas-fritas-receita-caseira.svg" style="max-width:100%;" alt="Batatas Fritas - Receita Caseira">
			</div>

			<div class="container">
				<div class="row mb80">

					<div class="produto col-8 offset-2 col-md-4 offset-md-1 text-center">
						<router-link to="/produtos" class="product-link">
							<img :src="pageBaseUrl+'/'+pageinfo.prod1image.path" :alt="pageinfo.prod1titulo" style="width:100%">
						</router-link>
						<h3>{{ pageinfo.prod1titulo }}</h3>
						<p>{{ pageinfo.prod1subtit }}</p>
						<router-link to="/produtos" tag="button" class="btn btn-outline-light">Saiba Mais</router-link>
					</div>

					<div class="produto col-8 offset-2 col-md-4 text-center">
						<router-link to="/produtos" class="product-link">
							<img :src="pageBaseUrl+'/'+pageinfo.prod2image.path" :alt="pageinfo.prod2titulo" style="width:100%">
						</router-link>
						<h3>{{ pageinfo.prod2titulo }}</h3>
						<p>{{ pageinfo.prod2subtit }}</p>
						<router-link to="/produtos" tag="button" class="btn btn-outline-light">Saiba Mais</router-link>
					</div>
					
				</div>
			</div>

		</div>

		<div id="home-receitas" class="container-fluid">
			<div class="container mb100">

				<div class="row mb40">
					<div class="col-12 col-md-8 text-center">
						<img src="@/images/textos/saborosas-receitas.svg" style="max-width:100%;" alt="Saborosas Receitas">
					</div>
				</div>

				<div class="row d-flex flex-row justify-content-center">

					<div id="home-receita1" class="text-center">
						<router-link 
							v-if="receitas[0]"
							:to="'/receita/' + receitas[0].titulo_slug"
							class="receita d-block moldura3 text-left"
							:style="{ backgroundImage: 'url(//cerroaguia.com/' + receitas[0].image.path + ')' }"
						>
							<div class="overlay">
								<p v-if="receitas[0].chef" class="chef">Do chef  {{ receitas[0].chef }}</p>
								<h3>{{ receitas[0].titulo }}</h3>
								<p v-if="receitas[0].doses" class="num-pessoas">PARA {{ receitas[0].doses }} PESSOAS</p>
							</div>
						</router-link>

						<router-link to="/receitas" tag="button" class="btn btn-outline-light mt30">Veja as receitas</router-link>
					</div>

					<router-link 
						v-if="receitas[1]"
						:to="'/receita/' + receitas[1].titulo_slug" 
						id="home-receita2" 
						class="receita d-block moldura3 text-left"
						:style="{ backgroundImage: 'url(//cerroaguia.com/' + receitas[1].image.path + ')' }"
					>
						<div class="overlay">
							<p v-if="receitas[1].chef" class="chef">Do chef  {{ receitas[1].chef }}</p>
							<h3>{{ receitas[1].titulo }}</h3>
							<p v-if="receitas[1].doses" class="num-pessoas">PARA {{ receitas[1].doses }} PESSOAS</p>
						</div>
					</router-link>
	
					<router-link 
						v-if="receitas[2]"
						:to="'/receita/' + receitas[2].titulo_slug"
						id="home-receita3" 
						class="receita d-block moldura3 text-left"
						:style="{ backgroundImage: 'url(//cerroaguia.com/' + receitas[2].image.path + ')' }"
					>
						<div class="overlay">
							<p v-if="receitas[2].chef" class="chef">Do chef  {{ receitas[2].chef }}</p>
							<h3>{{ receitas[2].titulo }}</h3>
							<p v-if="receitas[2].doses" class="num-pessoas">PARA {{ receitas[2].doses }} PESSOAS</p>
						</div>
					</router-link>
					
				</div>

			</div>
		</div>

		<div id="home-mapa" class="container mb40">
			<div class="row text-center">

				<div class="col-12 col-md-6">
					<router-link to="/ondecomprar" class="mapa-link">
						<img src="@/images/map-mockup.png" width="100%" >
					</router-link>
				</div>

				<div class="col-12 col-md-6 d-flex align-items-center">
					<div>
						<h3>{{ pageinfo.mapatit }}</h3>
						<p>{{ pageinfo.mapasubtit }}</p>
						<router-link to="/ondecomprar" tag="button" class="btn btn-outline-light">Ver Mapa</router-link>
					</div>
				</div>

			</div>
		</div>
	
		<MainFooter />

	</div>

</template>

<script>
/* IMPORT SCRIPTS */
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import axios from "axios";

/* DB CONNECTIONS */
const requestOne = axios.get(globals.COCKPIT_SERVER+"singletons/get/home"+globals.COCKPIT_TOKEN);
const requestTwo = axios.get(globals.COCKPIT_SERVER+"collections/get/receitas"+globals.COCKPIT_TOKEN+"&limit=3&sort[_created]=-1");

export default {
	name: "Inicio",
	components: {
		MainFooter
	},
	data() {
		return {
			dataReady: false,
			pageinfo: null,
			receitas: null,
			pageBaseUrl: globals.BASE_URL
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-inicio';
	},
	mounted: async function () {
		await axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
			this.pageinfo = responses[0].data
			this.receitas = responses[1].data.entries
			this.dataReady = true
			this.changeMetaData()
		}))
	},
	metaInfo() {
		return {
			title: 'Início'
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = 'Batatas fritas sem pressa no Alentejo';
		}
	}
};
</script>
