import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../views/inicio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio
  },
  {
    path: "/historia",
    name: "História",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "historia" */ "../views/historia.vue")
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: () =>
      import(/* webpackChunkName: "produtos" */ "../views/produtos.vue")
  },
  {
    path: "/receitas",
    name: "Receitas",
    component: () =>
      import(/* webpackChunkName: "receitas" */ "../views/receitas.vue")
  },
  {
    path: "/receita/:slug",
    name: "Receita",
    component: () =>
      import(/* webpackChunkName: "receita" */ "../views/receita.vue")
  },
  {
    path: "/ondecomprar",
    name: "OndeComprar",
    component: () =>
      import(/* webpackChunkName: "ondecomprar" */ "../views/ondecomprar.vue")
  },
  {
    path: "/contactos",
    name: "Contactos",
    component: () =>
      import(/* webpackChunkName: "contactos" */ "../views/contactos.vue")
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  /*
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
  */
});

export default router;
