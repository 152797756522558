const BASE_URL = "//cerroaguia.com"
const COCKPIT_SERVER = "//cerroaguia.com/cockpit/api/";
const COCKPIT_TOKEN = "?token=82fde82f5f1294982395dee1c5681d";
const COCKPIT_SERVERX = "//xmat.pt/cockpit/api/";
const COCKPIT_TOKENX = "?token=a8c4cd03f29e2c9e2e19a5f9dac532";

export default {
	BASE_URL: BASE_URL,
	COCKPIT_SERVER: COCKPIT_SERVER,
	COCKPIT_TOKEN: COCKPIT_TOKEN,
	COCKPIT_SERVERX: COCKPIT_SERVERX,
	COCKPIT_TOKENX: COCKPIT_TOKENX
}