<template>
  <footer id="footer">

			<img id="footer-logo" src="@/images/logo-footer.svg">

			<nav>
				<router-link to="/" exact class="footer-link">Início</router-link>
				<router-link to="/historia" class="footer-link">História</router-link>
				<router-link to="/produtos" class="footer-link">Produtos</router-link>
				<router-link to="/receitas" class="footer-link">Receitas</router-link>
				<router-link to="/ondecomprar" class="footer-link">Onde Comprar</router-link>
				<router-link to="/contactos" class="footer-link">Contactos</router-link>
			</nav>

			<div class="container">

				<div id="footer-social-icons" class="row mb20">
					<div class="col-12 text-center">
						<a href="https://www.facebook.com/Cerro-da-Aguia-590165234483699" target="_blank">
							<img src="@/images/icon-facebook.svg">
						</a>
						<a href="#" target="_blank">
							<img src="@/images/icon-youtube.svg">
						</a>
					</div>
				</div>

				<div class="row">
					<div class="col-12 col-md-6 text-center text-md-left">
						©2020 - Todos os direitos reservados
					</div>
					<div class="col-12 col-md-6 text-center text-md-right">
						<a href="https://bwg.pt" target="_blank">MADE BY BWG</a>
					</div>
				</div>

			</div>

		</footer>
</template>


<script>
export default {
  name: "main-footer"
};
</script>