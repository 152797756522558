import Vue from "vue";
import App from "./App.vue";
import router from "./config/router";
import VueMeta from 'vue-meta';
import "fontsource-lato/400.css"
import "fontsource-lato/700-normal.css"
import "fontsource-lato/900-normal.css"
import "@/css/style.scss";

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");


/* CREATE BUS FOR EVENT COMMUNICATION
------------------------------------------------------------------------------------------- */
export const bus = new Vue()


/* PLUGINS (Global Functions)
------------------------------------------------------------------------------------------- */
const encodeURL = {
  install(Vue) {
    Vue.prototype.cleanUrl = (url) => {
      var urlfixed = url.replace(/\s+/g, '-').toLowerCase();
      return urlfixed;
    }
  },
}
Vue.use(encodeURL)


const hamburger = {
  install(Vue) {
    Vue.prototype.hamburgerToggle = () => {
      var element = document.getElementById("menu-open-close");
      element.classList.toggle("is-active");
    }
  },
}
Vue.use(hamburger)
/*
function toggleMenu () {
  var element = document.getElementById("menu-open-close");
  element.classList.toggle("is-active");
}
*/